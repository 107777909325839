<template>
  <div>
    <v-container fluid grid-list-xl class="p-0 mb-5">
      <v-layout wrap>
        <v-flex xs12 sm3 class="flex-card" height="100%">
          <v-card class="grow">
            <v-card-title>
              New
            </v-card-title>
            <v-card-text>
              {{ commissionInformation.currency ? commissionInformation.currency : 'DKK' }} {{ (commissionInformation.new ? (commissionInformation.new / 100) : 0) | local_numbers }}
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm3 class="flex-card" height="100%">
          <v-card class="grow">
            <v-card-title>
              Being processed
            </v-card-title>
            <v-card-text>
              {{ commissionInformation.currency ? commissionInformation.currency : 'DKK' }} {{ (commissionInformation.pending ? (commissionInformation.pending / 100) : 0) | local_numbers }}
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm3 class="flex-card" height="100%">
          <v-card class="grow">
            <v-card-title>
              Ready for payout
            </v-card-title>
            <v-card-text>
              {{ commissionInformation.currency ? commissionInformation.currency : 'DKK' }} {{ (commissionInformation.ready ? (commissionInformation.ready / 100) : 0) | local_numbers }}
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm3 class="flex-card" height="100%">
          <v-card class="grow">
            <v-card-title>
              Legacy Unpaid
            </v-card-title>
            <v-card-text>
              {{ commissionInformation.currency ? commissionInformation.currency : 'DKK' }} {{ (commissionInformation.legacy_unpaid || 0) | local_numbers }}
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm4 class="flex-card" height="100%">
          <v-card class="grow">
            <v-card-title>
              Change in performance the last 30 days
            </v-card-title>
            <v-card-text :style="{color: commissionInformation.performance < -30 ? 'red' : 'green'}">
              {{ commissionInformation.performance | local_numbers }} %
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Payouts</div>
          <p class="subtitle-1 light-grey">Funds being transfered to your bank account</p>
        </div>
      </v-card-title>

      <payout-data-table
         :loading="loadingPayouts"
         :items="items"
         @next="loadPayments"

         :payout-account="payoutAccount"
      ></payout-data-table>
    </v-card>
  </div>
</template>

<script>
import PayoutDataTable from "../../data-tables/PayoutsDataTable";
import { mapState, mapActions } from "vuex";
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";

export default {
  components: { PayoutDataTable },
  filters: { local_numbers, utc_to_local_datetime },
  data: () => ({
    loading: true,
    payoutAccount: null,
    items: [],
    commissionInformation: {},
    lastPayoutId: null,
    loadingPayouts: true
  }),
  computed: {
    ...mapState("core/auth", ["user"])
  },
  methods: {
    ...mapActions("influencer", [
      "getPayouts",
      "getinfluencerCommission"
    ]),
    loadPayments() {
      this.loadingPayouts = true;
      const params = {
        influencer_uuid: this.user.influencer.uuid,
        starting_after: this.lastPayoutId,
        limit: 100
      };
      this.getPayouts(params).then(response => {
        this.loadingPayouts = false;
        if (response.data.length !== 0) {
          for (let item of response.data) {
            this.items.push(item);
          }
          this.lastPayoutId = response.last_payout_id;
          this.loadPayments();
        }
      });
    }
  },
  created() {
    let activePayoutAccounts = this.user.influencer.payout_accounts.filter(item => item.active === true);

    if(activePayoutAccounts.length > 0) {
      this.payoutAccount = activePayoutAccounts[0];
    }

    this.getPayouts({
      payout_account_id: this.payoutAccount.uuid,
      limit: 100
    }).then(response => {
      this.loadingPayouts = false;
      this.items = response.data;
      this.lastPayoutId = response.last_payout_id;
    });

    this.getinfluencerCommission().then(data => {
      this.commissionInformation = data;
    });
  }
};
</script>
